import {
  mdiChevronRight,
  mdiClipboardListOutline,
  mdiStar,
  mdiStarOutline,
  mdiTrashCanOutline,
  mdiTrophy,
} from "@mdi/js";

export default {
  REMOVE: mdiTrashCanOutline,
  COMPETITION: mdiTrophy,
  RIGHT_ARROW: mdiChevronRight,
  STAR: mdiStar,
  STAR_OUTLINE: mdiStarOutline,
  ROUND: mdiClipboardListOutline,
} as Record<string, string>;
