import { CompetitorModel, MatchModel } from "@memberpoint/ba-result-components";
import MatchCapabilities from "@/Classes/MatchCapabilities";
import MatchResult from "@/Classes/MatchResult";

export default class MatchExtModel extends MatchModel {
  protected _scorableContexts: string[] = [];
  protected _isCurrentUserHomeCompetitor = false;
  protected _isCurrentUserAwayCompetitor = false;
  protected _matchCapabilities: MatchCapabilities = new MatchCapabilities();
  protected _poolName: string | null = null;
  protected _roundName: string | null = null;
  protected _resultOptions: Record<string, unknown> = {};
  protected _result: MatchResult | null = null;

  /**
   * Returns an instance of MatchExtModel from the MatchModel.
   *
   * @param {MatchModel} match
   * @return {MatchExtModel}
   */
  static createFromMatchModel(match: MatchModel): MatchExtModel {
    const matchExt: MatchExtModel = new MatchExtModel(
      match.id,
      match.competitorOne,
      match.competitorTwo,
      match.round,
      match.pool,
      match.matchState,
      match.matchTimeUtc,
      match.timeUTCOffset,
      match.timezone
    );

    if (typeof match.competitionLabel === "string") {
      matchExt.setCompetitionLabel(match.competitionLabel);
    }

    if (typeof match.venueLabel === "string") {
      matchExt.setCompetitionLabel(match.venueLabel);
    }

    if (match.competition) {
      matchExt.setCompetition(match.competition);
    }

    if (match.venue) {
      matchExt.setVenue(match.venue);
    }

    if (match.result instanceof MatchResult) {
      matchExt.setResult(match.result);
    }

    matchExt.setIsFinalsSeries(match.isFinalsSeries);
    matchExt.setFixtureDetails(
      match.competitorOneSourceMatchId || "",
      match.competitorTwoSourceMatchId || "",
      match.winnerMatchId || ""
    );

    return matchExt;
  }

  /**
   * Returns the pool name.
   *
   * @return {string|null}
   */
  get poolName(): string | null {
    return this._poolName;
  }

  /**
   * @inheritDoc
   */
  get result(): MatchResult | null {
    return this._result;
  }

  /**
   * Returns the round name.
   *
   * @return {string|null}
   */
  get roundName(): string | null {
    return this._roundName;
  }

  /**
   * Returns the contexts that the match can be scored.
   *
   * @return {string}
   */
  get scorableContexts(): string[] {
    return this._scorableContexts;
  }

  /**
   * Returns TRUE if the current user is a home competitor for the match.
   *
   * @return {boolean}
   */
  get isCurrentUserHomeCompetitor(): boolean {
    return this._isCurrentUserHomeCompetitor;
  }

  /**
   * Returns TRUE if the current user is a away competitor for the match.
   *
   * @return {boolean}
   */
  get isCurrentUserAwayCompetitor(): boolean {
    return this._isCurrentUserAwayCompetitor;
  }

  /**
   * Returns the match capabilities.
   *
   * @return {MatchCapabilities}
   */
  get matchCapabilities(): MatchCapabilities {
    return this._matchCapabilities;
  }

  /**
   * Returns the result options.
   *
   * @return {Record}
   */
  get resultOptions(): Record<string, unknown> {
    return this._resultOptions;
  }

  /**
   * Set the pool name.
   *
   * @param {string} name
   */
  setPoolName(name: string) {
    return (this._poolName = name);
  }

  /**
   * Set the round name.
   *
   * @param {string} name
   */
  setRoundName(name: string) {
    return (this._roundName = name);
  }

  /**
   * Set the scorable contexts.
   *
   * @param {string[]} contexts
   */
  setScorableContexts(contexts: string[]): void {
    this._scorableContexts = contexts;
  }

  /**
   * Set whether the current user is a home competitor for the match.
   *
   * @param {boolean} flag
   */
  setIsCurrentUserHomeCompetitor(flag: boolean): void {
    this._isCurrentUserHomeCompetitor = flag;
  }

  /**
   * Set whether the current user is a away competitor for the match.
   *
   * @param {boolean} flag
   */
  setIsCurrentUserAwayCompetitor(flag: boolean): void {
    this._isCurrentUserAwayCompetitor = flag;
  }

  /**
   * Set the match capabilities.
   *
   * @param {MatchCapabilities} capabilities
   */
  setMatchCapabilities(capabilities: MatchCapabilities): void {
    this._matchCapabilities = capabilities;
  }

  /**
   * @inheritDoc
   */
  setResult(result: MatchResult): void {
    this._result = result;
  }

  /**
   * Set the result options.
   *
   * @param {Record} options
   */
  setResultOptions(options: Record<string, unknown>): void {
    this._resultOptions = options;
  }

  /**
   * Returns TRUE if the match is a BYE; otherwise FALSE.
   *
   * @return {boolean}
   */
  isBye(): boolean {
    return (
      (this.competitorOne instanceof CompetitorModel &&
        this.competitorOne.isBye()) ||
      (this.competitorTwo instanceof CompetitorModel &&
        this.competitorTwo.isBye())
    );
  }

  /**
   * Returns the competitor model instance for the current user.
   *
   * @return {CompetitorModel|null}
   */
  getCurrentUserCompetitor(): CompetitorModel | null {
    if (this.isCurrentUserHomeCompetitor) {
      return this.competitorOne;
    } else if (this.isCurrentUserAwayCompetitor) {
      return this.competitorTwo;
    }

    return null;
  }

  /**
   * Returns the competitor that the current user is not part of.
   *
   * @return {CompetitorModel|null}
   */
  getNonCurrentUserCompetitor(): CompetitorModel | null {
    const currentUserCompetitor = this.getCurrentUserCompetitor();

    if (!currentUserCompetitor) {
      return null;
    }

    return currentUserCompetitor.id === this.competitorOne.id
      ? this.competitorTwo
      : this.competitorOne;
  }
}
