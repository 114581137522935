




































































import { DateFormatter, MatchModel } from "@memberpoint/ba-result-components";
import Vue from "vue";
import MatchResult from "@/Classes/MatchResult";
import LogoAvatar from "@/components/LogoAvatar/index.vue";
import Icons from "@/assets/Icons";
import SavedMatches from "@/services/SavedMatches";
import {
  isCompetitorContextForMatch,
  isMarkerContextForMatch,
} from "@/services/ScoringContexts";
import MatchExtModel from "@/Classes/MatchExtModel";

interface MatchStatusTag {
  label: string;
  color: string;
}

export default Vue.extend({
  name: "MatchBlock",
  components: { LogoAvatar },
  props: {
    match: {
      type: MatchModel,
      required: true,
    },
    hideIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    favouriteToggle: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayContext: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      chevronIcon: Icons.RIGHT_ARROW,
      starIcon: Icons.STAR,
      starOutlineIcon: Icons.STAR_OUTLINE,
      isSaved: false,
    };
  },
  computed: {
    /**
     * Returns the match details label.
     *
     * @return {string}
     */
    matchDetailsLabel(): string {
      let poolLabel = `S:${this.match.pool}`;
      let roundLabel = `R:${this.match.round}`;

      if (this.match instanceof MatchExtModel) {
        if (this.match.poolName) {
          poolLabel = this.match.poolName;
        }

        if (this.match.roundName) {
          roundLabel = this.match.roundName;
        }
      }

      const parts = [roundLabel, poolLabel];

      if (this.matchDate !== "") {
        parts.push(this.matchDate);
      }

      return parts.join(" — ");
    },

    /**
     * Get the name of competitor one
     */
    competitorOneName(): string {
      if (this.match && this.match.competitorOne) {
        if (this.match.competitorOne.isBye()) {
          return "BYE";
        }
        return this.match.competitorOne.getName();
      }
      return "TBD";
    },

    /**
     * Get the name of competitor one
     */
    competitorOneLogo(): string | null {
      if (this.match && this.match.competitorOne) {
        if (this.match.competitorOne.hasLogo()) {
          return this.match.competitorOne.getLogo();
        }
      }
      return null;
    },

    /**
     * Get the name of competitor one
     */
    competitorTwoLogo(): string | null {
      if (this.match && this.match.competitorTwo) {
        if (this.match.competitorTwo.hasLogo()) {
          return this.match.competitorTwo.getLogo();
        }
      }
      return null;
    },

    /**
     * Get the name of competitor two
     */
    competitorTwoName(): string {
      if (this.match && this.match.competitorTwo) {
        if (this.match.competitorTwo.isBye()) {
          return "BYE";
        }
        return this.match.competitorTwo.getName();
      }
      return "TBD";
    },

    /**
     * Returns TRUE if the match is a finals-series; otherwise FALSE.
     *
     * @return {boolean}
     */
    isFinalsSeries(): boolean {
      return this.match.isFinalsSeries;
    },

    /**
     * Generate a string representation of the match date
     */
    matchDate(): string {
      if (!this.match.matchTimeUtc) {
        return "";
      }

      return DateFormatter.formatDate(this.match.matchTimeUtc, "D/M h:mm a z");
    },

    /**
     * Calculate the width of the viewport for the responsive list items
     */
    width(): string {
      return this.$vuetify.breakpoint.width + "px";
    },

    /**
     * Returns TRUE if the match has scores; otherwise FALSE.
     *
     * @return {boolean}
     */
    matchHasScores(): boolean {
      if (this.match.result instanceof MatchResult) {
        return (
          this.match.result.competitorOneShots !== null ||
          this.match.result.competitorTwoShots !== null
        );
      }

      return false;
    },

    /**
     * Returns the match status tag.
     *
     * @return {MatchStatusTag|null}
     */
    matchStatusTag(): MatchStatusTag | null {
      const status = this.match.result ? this.match.result.status : null;

      if (status === "forfeit") {
        return {
          label: "Forfeit",
          color: "orange lighten-2",
        };
      }

      if (status === "non-played") {
        return {
          label: "Unplayed",
          color: "orange lighten-2",
        };
      }

      if (status === "pending") {
        if (this.matchHasScores) {
          return {
            label: "In progress",
            color: "accent",
          };
        }

        return {
          label: "Pending",
          color: "default",
        };
      }

      if (this.match.result && this.match.result.isFinalized === true) {
        return {
          label: "Completed",
          color: "success",
        };
      }

      return null;
    },

    /**
     * Returns TRUE if current user is in a competitor context for the match; otherwise FALSE.
     * If a user has a "competitor" context even though the current user the "marker" context,
     * the user is still in a competitor context.
     *
     * @return {boolean}
     */
    isCompetitorContext(): boolean {
      return isCompetitorContextForMatch(this.match);
    },

    /**
     * Returns TRUE if current user is in a marker context for the match; otherwise FALSE.
     * A marker context is when a user has a "marker" context and no "competitor" context.
     *
     * @return {boolean}
     */
    isMarkerContext(): boolean {
      return isMarkerContextForMatch(this.match);
    },
  },

  watch: {
    match: {
      immediate: true,
      handler(nv: MatchModel) {
        this.isSaved = SavedMatches.hasMatch(nv.id);
      },
    },
  },

  methods: {
    toggleFavourite() {
      if (SavedMatches.hasMatch(this.match)) {
        SavedMatches.removeMatch(this.match);
      } else {
        SavedMatches.addMatch(this.match);
      }

      this.isSaved = SavedMatches.hasMatch(this.match.id);
    },
    onClick() {
      this.$emit("click");
    },
  },
});
