// TODO: this should be using the Result model when it has been updated.
import { ResultModel } from "@memberpoint/ba-result-components";

export default class MatchResult extends ResultModel {
  protected _isFinalized: boolean;
  protected _status: string;
  protected _wonBy: number | null;
  protected _competitorOneShots: number | null;
  protected _competitorTwoShots: number | null;
  protected _competitorOnePoints: number | null;
  protected _competitorTwoPoints: number | null;
  protected _handle: string;
  protected _hasConfirmation: boolean;

  constructor(
    handle: string,
    competitorOneShots: number | null,
    competitorTwoShots: number | null,
    competitorOnePoints: number | null,
    competitorTwoPoints: number | null,
    status = "",
    wonBy: number | null,
    isFinalized = false,
    hasConfirmation = false
  ) {
    super();

    this._handle = handle;
    this._competitorOneShots = competitorOneShots;
    this._competitorTwoShots = competitorTwoShots;
    this._competitorOnePoints = competitorOnePoints;
    this._competitorTwoPoints = competitorTwoPoints;
    this._status = status;
    this._wonBy = wonBy;
    this._isFinalized = isFinalized;
    this._hasConfirmation = hasConfirmation;
  }

  /**
   * Returns the shots for competitor 1.
   *
   * @return {?number}
   */
  get competitorOneShots(): number | null {
    return this._competitorOneShots;
  }

  /**
   * Returns the points for competitor 1.
   *
   * @return {?number}
   */
  get competitorOnePoints(): number | null {
    return this._competitorOnePoints;
  }

  /**
   * Returns the shots for competitor 2.
   *
   * @return {?number}
   */
  get competitorTwoShots(): number | null {
    return this._competitorTwoShots;
  }

  /**
   * Returns the points for competitor 2.
   *
   * @return {?number}
   */
  get competitorTwoPoints(): number | null {
    return this._competitorOnePoints;
  }

  /**
   * Returns the status for the result of the match.
   *
   * @return {string}
   */
  get status(): string {
    return this._status;
  }

  /**
   * Returns the winner number where 1 represents competitor 1 as
   * the winner, 2 as competitor 2 and NULL if there is no winner.
   *
   * @return {?number}
   */
  get wonBy(): number | null {
    return this._wonBy;
  }

  /**
   * Returns TRUE if the result for the match has been finalized; otherwise FALSE.
   *
   * @return {boolean}
   */
  get isFinalized(): boolean {
    return this._isFinalized;
  }

  /**
   * Returns the result handle.
   *
   * @return {string}
   */
  get handle(): string {
    return this._handle;
  }

  /**
   * Returns TRUE if the result has been confirmed; otherwise FALSE.
   *
   * @return {boolean}
   */
  get hasConfirmation(): boolean {
    return this._hasConfirmation;
  }

  /**
   * Set the shots for competitor 1.
   *
   * @param {?number} shots
   */
  setCompetitorOneShots(shots: number | null): void {
    this._competitorOneShots = shots;
  }

  /**
   * Set the shots for competitor 2.
   *
   * @param {?number} shots
   */
  setCompetitorTwoShots(shots: number | null): void {
    this._competitorTwoShots = shots;
  }

  /**
   * Set the points for competitor 1.
   *
   * @param {?number} points
   */
  setCompetitorOnePoints(points: number | null): void {
    this._competitorOnePoints = points;
  }

  /**
   * Set the points for competitor 2.
   *
   * @param {?number} points
   */
  setCompetitorTwoPoints(points: number | null): void {
    this._competitorTwoPoints = points;
  }
}
