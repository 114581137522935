import { Mutable } from "@/types";

export default class MatchCapabilities {
  readonly canEnterEndScore: boolean = false;
  readonly canEnterResults: boolean = false;
  readonly canFinalize: boolean = false;
  readonly canSetAsForfeit: boolean = false;
  readonly canSetAsUnplayed: boolean = false;
  readonly canUnfinalize: boolean = false;
  readonly canConfirmResults: boolean = false;
  readonly canFinalizeForHomeTeam: boolean = false;

  constructor(capabilities: Record<string, boolean> = {}) {
    this.initializeCapabilities(capabilities);
  }

  /**
   * Set the capabilities from the given source.
   *
   * @param {Record} capabilities
   *
   * @private
   */
  private initializeCapabilities(
    capabilities: Record<string, boolean> = {}
  ): void {
    const hasOwnProperty = Object.prototype.hasOwnProperty;

    const mutableThis = this as Mutable<this>;

    if (hasOwnProperty.call(capabilities, "canEnterEndScore")) {
      mutableThis.canEnterEndScore = capabilities.canEnterEndScore;
    }

    if (hasOwnProperty.call(capabilities, "canEnterResults")) {
      mutableThis.canEnterResults = capabilities.canEnterResults;
    }

    if (hasOwnProperty.call(capabilities, "canFinalize")) {
      mutableThis.canFinalize = capabilities.canFinalize;
    }

    if (hasOwnProperty.call(capabilities, "canSetAsForfeit")) {
      mutableThis.canSetAsForfeit = capabilities.canSetAsForfeit;
    }

    if (hasOwnProperty.call(capabilities, "canSetAsUnplayed")) {
      mutableThis.canSetAsUnplayed = capabilities.canSetAsUnplayed;
    }

    if (hasOwnProperty.call(capabilities, "canConfirmResults")) {
      mutableThis.canConfirmResults = capabilities.canConfirmResults;
    }

    if (hasOwnProperty.call(capabilities, "canFinalizeForHomeTeam")) {
      mutableThis.canFinalizeForHomeTeam = capabilities.canFinalizeForHomeTeam;
    }
  }
}
