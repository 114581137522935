import { MatchModel } from "@memberpoint/ba-result-components";
import MatchExtModel from "@/Classes/MatchExtModel";

/**
 * Returns TRUE if the current user is a competitor for the match.
 *
 * @param match
 * @return {boolean}
 */
export function isCompetitorContextForMatch(match: MatchModel): boolean {
  if (match instanceof MatchExtModel) {
    return match.scorableContexts.indexOf("competitor") !== -1;
  }

  return false;
}

/**
 * Returns TRUE if the current user is a marker for the match.
 *
 * @param match
 * @return {boolean}
 */
export function isMarkerContextForMatch(match: MatchModel): boolean {
  if (match instanceof MatchExtModel) {
    return (
      match.scorableContexts.length === 1 &&
      match.scorableContexts[0] === "marker"
    );
  }

  return false;
}
