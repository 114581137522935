











import Vue from "vue";

export default Vue.extend({
  name: "LogoAvatar",
  props: {
    url: {
      type: String,
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    logoStyle(): Record<string, string> {
      return {
        "background-image": `url('${this.url}')`,
      };
    },

    /**
     * If there's a valid URL
     */
    hasLogo(): boolean {
      return this.url !== null && this.url.length > 0;
    },

    /**
     * Generate an abbreviated name
     */
    abbrName(): string {
      let name: string = this.name ?? "?";

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return name
        .match(/(\b\S)?/g)
        .join("")
        .match(/(^\S|\S$)?/g)
        .join("")
        .toUpperCase();
    },

    /**
     * Generate a color based on the name
     */
    generateColor(): string {
      let hash = 0;
      for (let i = 0; i < this.name.length; i++) {
        hash = Math.abs(Math.sin(this.name.charCodeAt(i) + hash) * 360);
      }
      let hsl = [hash, this.name.length > 0 ? "100%" : "0%", "42%"];
      return "hsl(" + hsl.join() + ")";
    },
  },
});
